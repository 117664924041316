import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  commercialHeadGetAllFacilities, commercialHeadGetContracts, commercialHeadGetDraftContracts,
  commercialHeadGetAllMeters, facmanGetUnassignedMeters, facmanGetAssignedMeters,
  commercialHeadGetAllFacilitiesForNav, commercialHeadGetContractsForDropdown, commercialHeadGetFMSContracts,
  commercialHeadGetContractsForDropdownTechnician, facilityManagerGetAllAssets
} from "../../Redux/Actions/Actions";
import axios from '../../axios-interceptor';
import { base_url } from '../../utilities';
import Loader from './loader';
import { set } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';
import { MdErrorOutline } from 'react-icons/md';
import { RECORDS_PER_PAGE } from '../../Config';
import { APP_BASE_MOD } from '../../Config';
import { encrypt } from './ReusableFunctions';

export default function NavBarTop() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [menuActive, setMenuActive] = useState(false)
  const [showProfile, setShowProfile] = useState(false)
  const facilitiesList = useSelector(state => state.facilitiesListForNav)

  const [cityFacilities, setCityFacilities] = useState({});
  const [selectedCityName, setSelectedCityName] = useState("")
  const [selectedFacilityId, setSelectedFacilityId] = useState("")
  const [facilitiesForDropdown, setFacilitiesForDropdown] = useState([])
  const [logoutError, setLogoutError] = useState(null)

  const [selectedEnvBase, setSelectedEnvBase] = useState()

  const getCurrentEnvironment = () => {
    const currentUrl = base_url;
    for (const ev in APP_BASE_MOD) {
      if (currentUrl.includes(ev)) {
        setSelectedEnvBase(APP_BASE_MOD[ev]);
      }
    }
  };

  const getEnvironmentIndicator = (environment) => {
    switch (environment) {
      case 'Dev':
        return <span className="environment-indicator blue"></span>;
      case 'QA':
        return <span className="environment-indicator orange"></span>;
      case 'UAT':
        return <span className="environment-indicator purple"></span>;
      case 'Live':
        return <span className="environment-indicator green"></span>;
      default:
        return null;
    }
  };


  useEffect(() => {
    getCurrentEnvironment();
    if (!facilitiesList) {
      dispatch(commercialHeadGetAllFacilitiesForNav());
    }
  }, [dispatch, facilitiesList]);

  useEffect(() => {

    const cityFacilities = {};
    facilitiesList?.forEach(facility => {
      const { city, name, facility_id } = facility;
      if (cityFacilities[city]) {
        cityFacilities[city].push({ name: name, facility_id: facility_id });
      } else {
        if (localStorage.getItem("selected_city_name") === "") {
          localStorage.setItem("selected_city_name", city)
          localStorage.setItem("selected_facility_id", facility_id)
          localStorage.setItem("selected_facility_name", name)
          // If path is commercial head users
          if (window.location.pathname === "/commercialHeadContracts") {
            dispatch(commercialHeadGetContracts({ facility_id: facility_id, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
            dispatch(commercialHeadGetDraftContracts({ facility_id: facility_id, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
            dispatch(commercialHeadGetAllMeters(
              {
                request_type: "dropdown",
                filter_condition: {
                  facility_id: facility_id,
                  is_in_use: 0,
                  is_working: 1
                }
              }
            ));
          }
          if (window.location.pathname === "/commercialHeadFMSContracts") {
            dispatch(commercialHeadGetFMSContracts({ facility_id: facility_id, status: "active", serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
          }
          // If path is electricity technician meter readings
          if (window.location.pathname === "/electricityMeterReadings") {
            dispatch(commercialHeadGetContractsForDropdownTechnician({ facility_id: facility_id }));
          }

          if (window.location.pathname === "/waterMeterReadings") {
            dispatch(commercialHeadGetContractsForDropdownTechnician({ facility_id: facility_id }));
          }

          if (window.location.pathname === "/FacilityManagerMeterMaster") {
            dispatch(facmanGetUnassignedMeters(
              {
                serach_text: "",
                items_per_page: RECORDS_PER_PAGE,
                page_number: 1,
                filter_condition: {
                  facility_id: facility_id,
                  is_in_use: 0,
                  is_working: 1
                }
              }
            ));

            dispatch(facmanGetAssignedMeters({
              serach_text: "",
              items_per_page: RECORDS_PER_PAGE,
              page_number: 1,
              filter_condition: {
                facility_id: facility_id
              }
            }
            ))

            dispatch(facilityManagerGetAllAssets({ facility_id: facility_id, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
          }
        }
        cityFacilities[city] = [{ name: name, facility_id: facility_id }];
      }
    });
    Object.keys(cityFacilities).forEach(city => {
      cityFacilities[city].sort((a, b) => a.name.localeCompare(b.name));
    });
    if (localStorage.getItem("selected_city_name") !== "") {
      setFacilitiesForDropdown(cityFacilities[localStorage.getItem("selected_city_name")])
    }
    setCityFacilities(cityFacilities);
  }, [dispatch, facilitiesList])

  const handleLogout = () => {
    setLoading(true)
    axios.post(base_url + "authorization/logout", encrypt({ username: localStorage.getItem("username") }))
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          navigate("/", { replace: true })
          setLoading(false)
        }
      })
      .catch((err) => {
        setLogoutError(err?.response?.data?.message)
        setLoading(false)
      })
  }

  const handleCityChange = (e) => {
    const cityValue = e.target.value;
    setSelectedCityName(cityValue);
    localStorage.setItem("selected_city_name", cityValue)
    localStorage.setItem("selected_facility_id", cityFacilities[cityValue][0].facility_id)
    localStorage.setItem("selected_facility_name", cityFacilities[cityValue][0].name)
    if (window.location.pathname === "/commercialHeadContracts") {
      dispatch(commercialHeadGetContracts({ facility_id: cityFacilities[cityValue][0].facility_id, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
      dispatch(commercialHeadGetDraftContracts({ facility_id: cityFacilities[cityValue][0].facility_id, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));

      dispatch(commercialHeadGetAllMeters(
        {
          request_type: "dropdown",
          filter_condition: {
            facility_id: cityFacilities[cityValue][0].facility_id,
            is_in_use: 0,
            is_working: 1
          }
        }
      ));
    }

    if (window.location.pathname === "/commercialHeadFMSContracts") {
      dispatch(commercialHeadGetFMSContracts({ facility_id: cityFacilities[cityValue][0].facility_id, status: "active", serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
    }

    if (window.location.pathname === "/electricityMeterReadings") {
      dispatch(commercialHeadGetContractsForDropdownTechnician({ facility_id: cityFacilities[cityValue][0].facility_id }));
    }

    if (window.location.pathname === "/waterMeterReadings") {
      dispatch(commercialHeadGetContractsForDropdownTechnician({ facility_id: cityFacilities[cityValue][0].facility_id }));
    }

    if (window.location.pathname === "/FacilityManagerMeterMaster") {
      dispatch(facmanGetUnassignedMeters(
        {
          serach_text: "",
          items_per_page: RECORDS_PER_PAGE,
          page_number: 1,
          filter_condition: {
            facility_id: cityFacilities[cityValue][0].facility_id,
            is_in_use: 0,
            is_working: 1
          }
        }
      ));

      dispatch(facmanGetAssignedMeters({
        serach_text: "",
        items_per_page: RECORDS_PER_PAGE,
        page_number: 1,
        filter_condition: {
          facility_id: cityFacilities[cityValue][0].facility_id
        }
      }
      ))

      dispatch(facilityManagerGetAllAssets({ facility_id: cityFacilities[cityValue][0].facility_id, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }))
    }

    setFacilitiesForDropdown(cityFacilities[cityValue])

    window.location.reload();

  }

  const handleFacilityChange = (e) => {
    const facilityId = e.target.value;

    const selectedIndex = e.target.selectedIndex;
    const facilityName = e.target.options[selectedIndex].text;
    setSelectedFacilityId(facilityId);
    localStorage.setItem("selected_facility_id", facilityId)
    localStorage.setItem("selected_facility_name", facilityName)
    if (window.location.pathname === "/commercialHeadContracts") {
      dispatch(commercialHeadGetContracts({ facility_id: facilityId, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
      dispatch(commercialHeadGetDraftContracts({ facility_id: facilityId, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));

      dispatch(commercialHeadGetAllMeters(
        {
          request_type: "dropdown",
          filter_condition: {
            facility_id: facilityId,
            is_in_use: 0,
            is_working: 1
          }
        }
      ));
    }
    if (window.location.pathname === "/commercialHeadFMSContracts") {
      dispatch(commercialHeadGetFMSContracts({ facility_id: facilityId, status: "active", serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }));
    }
    if (window.location.pathname === "/electricityMeterReadings") {
      dispatch(commercialHeadGetContractsForDropdownTechnician({ facility_id: facilityId }));
    }

    if (window.location.pathname === "/waterMeterReadings") {
      dispatch(commercialHeadGetContractsForDropdownTechnician({ facility_id: facilityId }));
    }

    if (window.location.pathname === "/FacilityManagerMeterMaster") {
      dispatch(facmanGetUnassignedMeters(
        {
          serach_text: "",
          items_per_page: RECORDS_PER_PAGE,
          page_number: 1,
          filter_condition: {
            facility_id: facilityId,
            is_in_use: 0,
            is_working: 1
          }
        }
      ));

      dispatch(facmanGetAssignedMeters({
        serach_text: "",
        items_per_page: RECORDS_PER_PAGE,
        page_number: 1,
        filter_condition: {
          facility_id: facilityId
        }
      }
      ))

      dispatch(facilityManagerGetAllAssets({ facility_id: facilityId, serach_text: "", items_per_page: RECORDS_PER_PAGE, page_number: 1 }))

    }
    window.location.reload();
  }
  // const environment = getCurrentEnvironment();

  return (
    <>
      {loading && <Loader />}
      {logoutError && <Modal keyboard={false} backdrop="static" show={logoutError} onHide={() => {
        setLogoutError(null)
      }} centered className="popper-modal">
        <Modal.Body className="text-center">
          <MdErrorOutline style={{ fontWeight: "10px", fontSize: "60px", color: "#Cc0202", opacity: 0.8 }} />
          <h5>Error!</h5>
          <p className="mb-3">{logoutError}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center" >
          <Button variant="secondary" className="secondary" onClick={() => {
            setLogoutError(null)
          }} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>}
      <div className={(selectedEnvBase?.name === "Dev") ? 'topNavBarDev' : ((selectedEnvBase?.name === "QA") ? 'topNavBarQa'
        : (selectedEnvBase?.name === 'UAT') ? 'topNavBarUat' : 'topNavBar')}>
        <div className='topNavItems'>
          <img src="./images/icons/menu.svg" alt=""
            className="menuIcon"
            onClick={() => setMenuActive(!menuActive)}
          />
          {
            window.location.pathname !== "/commercialHeadAddFacility" && window.location.pathname !== "/users" && window.location.pathname !== "/facilityManagerWorklog" ?
              <>
                <div className='topNavFilters'>
                  <select className='topNavdropdown' value={localStorage.getItem("selected_city_name")} onChange={(e) => handleCityChange(e)}>
                    {cityFacilities && Object.keys(cityFacilities).sort((a, b) => a.localeCompare(b)).map(city => {
                      return <option value={city}>{city}</option>
                    })}
                  </select>
                </div>

                <div className='topNavFilters'>
                  <select value={localStorage.getItem("selected_facility_id")} className='topNavdropdown'
                    onChange={(e) => handleFacilityChange(e)}
                  >
                    {
                      facilitiesForDropdown && facilitiesForDropdown?.map(facility => {
                        return <option key={facility.facility_id} value={facility.facility_id}>{facility.name}</option>

                      })
                    }
                  </select>
                </div>
              </> :
              <div></div>

          }

        </div>
        <div className='topNavIcons'>
          <img src="./images/icons/bellbell.svg" alt=""
            className="topNavIcon"

          />
          {
            selectedEnvBase &&
            <div className='environment-indicator-container'>
              <div style={{ fontWeight: 'bold' }}>
                {localStorage?.getItem("group")}
              </div>
              <div className={`${selectedEnvBase.bgcolor} env-bar`}>
                <span>
                  {getEnvironmentIndicator(selectedEnvBase?.name)}
                </span>
                <span>
                  {selectedEnvBase?.name}
                </span>
              </div>
            </div>

          }



          {localStorage.getItem("username") ? <div className='d-flex flex-column'>
            <span className='profile-icon C-pointer' onMouseOver={() => setShowProfile(true)} onClick={() => setShowProfile(!showProfile)}>{localStorage?.getItem("name")[0]?.toUpperCase()}</span>
            {showProfile &&
              <div className='profile-relative-container' onMouseLeave={() => setShowProfile(false)}>
                <div className='triangle'>

                </div>
                <div className='profile-container'>
                  <div className='profile-icon'>{localStorage?.getItem("name")[0].toUpperCase()}</div>
                  <div style={{ alignItems: 'center' }}><div style={{ alignItems: 'center' }}>{localStorage?.getItem("name")}</div></div>
                  <div >{localStorage?.getItem("email")}</div>
                  <button className='btn btn-secondary' onClick={handleLogout}>Logout</button>
                </div>
              </div>}
          </div> : <img src="./images/icons/useruser.svg" alt=""
            className="topNavIcon"
          />}
        </div>
      </div>

      {menuActive &&
        <>
          {
            window.location.pathname !== "/commercialHeadAddFacility" && window.location.pathname !== "/users" ?
              <>
                <div className='responsive_top_navbar'>

                  <div className='responsiveTopNavFilters'>
                    <select className='topNavdropdown' value={localStorage.getItem("selected_city_name")} onChange={(e) => handleCityChange(e)}>
                      {cityFacilities && Object.keys(cityFacilities).sort((a, b) => a.localeCompare(b)).map(city => {
                        return <option value={city}>{city}</option>
                      })}
                    </select>
                  </div>
                  <div className='responsiveTopNavFilters'>
                    <select value={localStorage.getItem("selected_facility_id")} className='topNavdropdown'
                      onChange={(e) => handleFacilityChange(e)}
                    >
                      {
                        facilitiesForDropdown && facilitiesForDropdown?.map(facility => {
                          return <option value={facility.facility_id}>{facility.name}</option>

                        })
                      }
                    </select>
                  </div>
                </div>

              </> : null
          }
        </>
      }
    </>
  )
}
